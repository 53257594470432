import React, { useEffect, useState } from 'react'
import styles from './header.module.scss'
import { Box, Grid, List, ListItemButton, ListItemText, Typography } from '@mui/material'
import logo from '../../assest/Intgrow_logo.png'
import { useNavigate } from 'react-router-dom'
import { IoReorderThreeSharp } from "react-icons/io5";
import MousePopover from '../MousePopover'


const Header = ({ logo, logo_1, color }) => {
    const navigate = useNavigate()
    const [show, setShow] = useState(false)
    const [headerColor, setHeaderColor] = useState(false)
    const [isAuthenticated, setAuthenticated] = useState(false)

    useEffect(() => {
        const isLogin = window.localStorage.getItem('isAuthenticated') === 'true';
        setAuthenticated(isLogin);

        const changeColor = () => {
            if (window.scrollY >= 90) {
                setHeaderColor(true);
            } else {
                setHeaderColor(false);
            }
        };

        window.addEventListener('scroll', changeColor);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('scroll', changeColor);
        };
    }, []);


    const menus = [
        {
            "id": 1,
            "label": "HOME",
            "path": "/"
        },
        // {
        //     "id": 2,
        //     "label": "BLOG",
        //     "path": "/blog"
        // },
        {
            "id": 3,
            "label": "SOLUTIONS",
            "path": "/solutions"
        }, 
        {
            "id": 4,
            "label": "APIs",
            "path": "/apis"
        }/*,
        {
            "id": 5,
            "label": "PRICING",
            "path": "/pricing"
        }*/
    ]


    const handleLogout = () => {
        window.localStorage.removeItem('isAuthenticated');
        window.localStorage.removeItem('access_token');
        window.localStorage.removeItem('userInfo');
        navigate("/auth/login")
        // logout({ returnTo: `${window.location.origin}/auth/login` });
    };
    return (
        <Grid container className={headerColor ? `${styles.headerContainer} ${styles.header_bg}` : `${styles.headerContainer}`}>
            <Grid item sm={4}>
                <img onClick={() => navigate('/')} src={headerColor ? logo_1 : logo} width={210} alt='' />
            </Grid>
            <Grid item sm={8} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                <Box>
                    {menus.map((item) => {
                        const element = (item?.label === 'SOLUTIONS') ?
                         <MousePopover key={item?.id} title={item?.label} navLinksArr={[{menuTitle:'HS Code Finder',menuLink:"hs-code-finder"}, {menuTitle:'Duty Calculator',menuLink:"duty-calculator"},{menuTitle:'DutySaver Pro',menuLink:"duty-saver-pro"}]} color={color} /> : 
                         <Typography key={item?.id} onClick={() => navigate(item?.path)} variant='h5' fontSize={15} sx={{ height: "fit-content", marginBlock: "auto", color: color, cursor: "pointer" }}>{item?.label}</Typography>
                        return element;
                    })}
                    {isAuthenticated ?
                        <>
                            <Typography onClick={() => navigate("/dashboard")} variant='h5' fontSize={15} sx={{ height: "fit-content", marginBlock: "auto", color: color, cursor: "pointer", width: "max-content" }}>DASHBOARD</Typography>
                            <Typography onClick={handleLogout} variant='h5' fontSize={15} sx={{ height: "fit-content", marginBlock: "auto", color: color, cursor: "pointer", width: "max-content" }}>LOG OUT</Typography>
                        </>
                        :
                        <Typography onClick={() => navigate("/auth/login")} variant='h5' fontSize={15} sx={{ height: "fit-content", marginBlock: "auto", color: color, cursor: "pointer" }}>LOGIN</Typography>}
                </Box>
                <Box>
                    <IoReorderThreeSharp fontSize={51} cursor={"pointer"} onClick={() => setShow(!show)} />
                </Box>
                {show &&
                    <Grid className={styles.menu}>
                        <List>
                            {menus.map((item) => {

                                return (
                                    <ListItemButton onClick={() => { navigate(item.path); setShow(false) }}>
                                        <ListItemText>{item.label}</ListItemText>
                                    </ListItemButton>
                                )
                            })}
                            {isAuthenticated ?
                                <>
                                    <ListItemButton onClick={() => { navigate("/dashboard"); setShow(false) }}>
                                        <ListItemText>Dashboard</ListItemText>
                                    </ListItemButton>
                                    <ListItemButton onClick={handleLogout}>
                                        <ListItemText>Log Out</ListItemText>
                                    </ListItemButton>

                                </>
                                :
                                <ListItemButton onClick={() => navigate("/auth/login")}>
                                    <ListItemText>Login</ListItemText>
                                </ListItemButton>
                            }
                        </List>
                    </Grid>}
            </Grid>
        </Grid>
    )
}

export default Header